@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', serif;
}

.btn-green {
  @apply rounded-3xl bg-green-500 whitespace-nowrap py-2 px-5 text-black text-base outline-none border-none cursor-pointer transition ease-in-out duration-200 no-underline hover:bg-white hover:text-black;
}

.clip-svg {
  clip-path: url(#svgMask);
}

.leading-normal {
  line-height: normal;
}

.leading-160 {
  line-height: 160% !important;
}

.leading-148 {
  line-height: 148% !important;
}

.leading-120 {
  line-height: 120% !important;
}

.leading-140 {
  line-height: 140% !important;
}
.text-130{
  font-size: 8.9vw;
}

.leading-200 {
  line-height: 200% !important;
}

.leading-70 {
  letter-spacing: -0.7px;
}
.leading-107 {
  line-height: 107% !important;
}
.tracking-63 {
  letter-spacing: -0.63px;
}
.tracking-72 {
  letter-spacing: -0.72px;
}
.tracking-164 {
  letter-spacing: -1.64px;;
}
.tracking-184 {
  letter-spacing: -1.845px;
}

.border-gray-250 {
  border-color: rgba(0, 0, 0, 0.10);
}

.bg-gray-280 {
  background-color: #D9CFC2;
}

.text-yellow-1 {
  color: #FFF4E4;
}
.border-yellow-1 {
  border-color: #FFF4E4;
}
.bg-yellow-1 {
  background-color: #FFF4E4;
}
.rounded-half-full{
  border-radius: 50px;
}

.crypto-buttons:hover,
.crypto-buttons.active{
  background: black;
  border-color: black;
  color: #FFF4E4;
}
.folder .folder-year{
  font-size: 17px;
}
.folder .folder-head{
  font-size: 48px;
  line-height: 48px;
  margin-top: 50px;
}
.folder .folder-foot{
  margin-top: 35px;
}

.card-scroll{
  height: 290px;
}

.average-return{
  width: 182px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.border-gray-270 {
  border-color: rgba(0, 0, 0, 0.15);
}

.border-30 {
  border-radius: 30px;
}

.border-1 {
  border-width: 1px;
}

.bg-full {
  background-size: 100% 100%;
  /* -webkit-mask-box-image: url(./images/about_mask.svg);
  mask: url(./images/about_mask.svg); */
}

.react-multiple-carousel__arrow {
  z-index: 1;
}

.overflow-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.overflow-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.font-pathway {
  font-family: 'Pathway Extreme'; 
}

.border-opacity-15 {
  --tw-border-opacity: 0.15;
}

.pt-3-12{
  padding-top: 22%;
}

.custom-mask{
  top: -1px;
  right: 15px;
}

.tab-background {
  background-image: url('./images/tab_bg.svg');
  background-color: black;
  background-size: 100% auto;
  height: 595px;
  border-radius: 3rem;
  margin-bottom: 60px;
}

.folder-header{
  width: 87px;
  margin-left: auto;
  text-wrap: wrap;
  word-wrap: break-word;
  line-height: 18px;
  text-align: center;
  padding-right: 30px;
}

@media only screen and (max-width: 850px) {
.tab-background{
  display: flex;
  flex-direction: column;
}
.tab-buttons{
  display: flex;
  flex-wrap: wrap;
}
}
@media only screen and (max-width: 1300px) {
  .tab-background{
    background-color: black;
    height: 100%;
  }
}
@media only screen and (min-width: 1480px) {
  .custom-mask{
    right: 20px;
  }
}

@media only screen and (min-width: 1715px) {
  .custom-mask{
    right: 23px;
  }
}

@media only screen and (min-width: 2130px) {
  .custom-mask{
    right: 30px;
  }
}
@media only screen and (min-width: 2560px) {
  .custom-mask{
    right: 35px;
  }
}